
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
class RangeFilter extends Component {
    constructor(props) {
        super(props);
        this.filter = this.filter.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    getValue() {
        return {
            min: this.min.value,
            max: this.max.value
        };
    }
    filter() {
        this.props.onFilter(this.getValue());
    }

    render() {
        return <form id="filter-date">
            <div >
                <div>
                    <label>From</label>
                    <br></br>
                    <input
                        style={{ display: 'block' }}
                        onChange={this.filter}
                        key="min"
                        ref={node => (this.min = node)}
                        type="datetime-local"
                        placeholder="From"
                    />
                </div>
                <div>
                    <label style={{ width: '29px' }}>To</label>
                    <br></br>
                    <input
                        onChange={this.filter}
                        key="max"
                        ref={node => (this.max = node)}
                        type="datetime-local"
                        placeholder="To"
                    />
                </div>
            </div>
        </form>
    }
};
export default (RangeFilter)

