/* eslint-disable default-case */
const initialState = {
};
const updateAction = (state = initialState, action) => {
    switch (action.type) {

        case "USER_AUTHENTICATED":
            return {
                ...state,
                user: action.user,
                authClient: action.msal,
                userAuthenticated: true,
            };
        case "SET_SUPERVISED_AGENTS":
            return {
                ...state,
                supervisedAgents: action.agents,
            };
        case "SET_CALLFLOWS":
            return {
                ...state,
                callflows: action.callflows,
            };

    }
};

export default updateAction;
