import React from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { authContext } from "../auth";
import "../assets/CSS/Recording.css";
import RecordingComponent from '../components/RecordingComponent'
import Welcome from "../components/WelcomeComponent/Welcome";
import { setSupervisedAgents, setCallflows } from '../redux/action'
const Recording = (props) => {
    authContext.handleWindowCallback();
    let user = authContext.getCachedUser();

    if (user) {
        return (
            <div id="Recording">
                <div>
                    <RecordingComponent
                        setSupervisedAgents={props.setSupervisedAgents}
                        setCallflows={props.setCallflows}
                        supervisedAgents={props.supervisedAgents}
                        callflows={props.callflows}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <Welcome />
        )
    }
}
const mapStateToProps = (state) => ({
    authState: state?.userAuthenticated,
    supervisedAgents: state?.supervisedAgents,
    callflows: state?.callflows
})
const mapDispatchToProps = (dispatch) => ({
    setSupervisedAgents: (agents) => dispatch(setSupervisedAgents(agents)),
    setCallflows: (callFlows) => dispatch(setCallflows(callFlows)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps)(Recording);
