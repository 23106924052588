import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Recording from "./containers/Recording";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "@microsoft/mgt-react";
import AgentCallsContainer from './containers/AgentCallsContainer'
class App extends Component {

  render() {

    return (
      <Router>
        <div style={{ height: '100%' }}>
          <div id="login-navigation">
            <Login id="login" />
          </div>
          <Routes>
            < Route exact path="/" element={<Recording />} />
            < Route exact path={`/agentCall/:agentID/:callId`} id_ render={(props) => <AgentCallsContainer />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
