import React from "react";
import { Login } from "@microsoft/mgt-react";
import { Flex, Header } from "@fluentui/react-northstar";
import Illustration from "./reportingIllustration";

class welcome extends React.Component {
    render() {
        const styles = {
            illust: {
                height: "350px",
            },
        };
        return (
            <div id="welcome-div">
                <Flex column>
                    <Flex hAlign="center" vAlign="center">
                        <Header as="h1" id='welcome-message' content="Welcome to Heedify Recording" />
                    </Flex>
                    <Flex hAlign="center" vAlign="center">
                        <Illustration style={styles.illust} />
                    </Flex>
                    <Flex hAlign="center" vAlign="center" id="parent-login" >
                        <Login ></Login>
                    </Flex>
                </Flex>
            </div>
        );
    }
}
export default welcome;
