import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import App from "./App";
import adminReducers from "./redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";
import { authContext } from "./auth";
import "./index.css";
import 'html-duration-picker/dist/html-duration-picker.min.js'
import {
  Providers,
  SimpleProvider,
  ProviderState,
} from "@microsoft/mgt";
function getAccessToken(scopes) {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      "https://graph.microsoft.com",
      function (errDesc, token, err, tokenType) {
        if (token) {
          Providers.globalProvider.setState(ProviderState.SignedIn);
          resolve(token);
        }
      }
    );
  });
}
function login() {
  authContext.handleWindowCallback();
  authContext.login().then(() => {
    Providers.globalProvider.setState(ProviderState.SignedIn);
  });
}
function logout() {
  authContext.logOut();
}
let provider = new SimpleProvider(getAccessToken, login, logout);
Providers.globalProvider = provider;
authContext.handleWindowCallback();
let user = authContext.getCachedUser();
if (user) {
  Providers.globalProvider.setState(ProviderState.SignedIn);
} else {
}
const store = createStore(
  adminReducers, /* preloadedState, */
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
