import RecordedCallsComponents from '../components/RecordedCallsComponents'
import { connect } from "react-redux";
const mapStateToProps = (state) => ({
    supervisedAgents: state?.supervisedAgents,
    callflows: state?.callflows
})
const mapDispatchToProps = (dispatch) => ({
})
export default connect(
    mapStateToProps,
    mapDispatchToProps)(RecordedCallsComponents);