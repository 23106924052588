export const authenticate = (user, msal) => ({
    type: "USER_AUTHENTICATED",
    user,
    msal,
});
export const setCallflows = (callflows) => ({
    type: "SET_CALLFLOWS",
    callflows,
})
export const setSupervisedAgents = (agents) => ({
    type: "SET_SUPERVISED_AGENTS",
    agents,

})
