export const getAllCallFlowManagementUrl = (tenantId, userId, scope) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/CallFlowManagement/" +
        tenantId +
        "/" + scope + "/get/ALL?userid=" +
        userId
    );
};
export const updateCallFlowManagementUrl = (tenantId, userId, CallFlowId) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/CallFlowManagement/" +
        tenantId +
        "/default/update/" +
        CallFlowId +
        "?userid=" +
        userId
    );
};
export const selectCallFlowManagementUrl = (tenantId, userId, CallFlowId) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/CallFlowManagement/" +
        tenantId +
        "/default/get/" +
        CallFlowId +
        "?userid=" +
        userId
    );
};
export const getAllAgentGroupsURL = (tenant, scope, agentId) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/GroupManagement/" +
        tenant +
        "/" +
        scope +
        "/get/ALL?userid=" +
        agentId
    );
};
export const getAllScopsUrl = (tenantId, Token) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/ScopeManagement/" +
        tenantId +
        "/get/ALL?token=" +
        Token
    );
};
export const getMyAgentProfileUrl = (tenantId, userId, Token) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/MyAgentProfile/" +
        tenantId +
        "/" +
        userId +
        "?token=" +
        Token
    );
};
export const getAgentUrl = (tenantId, scope, userId, agentId, grouid) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/AgentManagement/" +
        tenantId +
        "/" +
        scope +
        "/get/" +
        agentId +
        "?userid=" +
        userId +
        "&groupid=" +
        grouid
    );
};
export const getRecordingsaccessUrl = (tenant, scope, userId, recordingscope) => {
    return (
        "https://heedifyprovisioningservices.azurewebsites.net/Callrecordingsaccess/"
        + tenant
        + "/" + scope
        + "/" + recordingscope
        + "?userid="
        + userId
    );
};