import React, { useEffect } from "react";
import RecordedCallsContainer from '../containers/RecordedCallsContainer'
import { getAllCallFlowManagementUrl, getAllAgentGroupsURL, getAllScopsUrl, getAgentUrl } from "../urls";
import { authContext } from "../auth";
import axios from "axios";
import { getToken } from './Graph';
const RecordingComponent = (props) => {
    let user = authContext.getCachedUser();
    let userId = user.profile.oid;
    let tenant = user.profile.tid;
    const getCallflows = (scope) => {
        return new Promise(async (resolve, reject) => {
            let url = getAllCallFlowManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, scope)
            axios.post(url).then((scopeCallflows) => {
                resolve(scopeCallflows.data)
            });
        })
    }
    const getAgentsGroup = (scope, agentId) => {
        return new Promise(async (resolve, reject) => {
            let url = getAgentUrl(tenant, scope, userId, agentId, null)
            axios.get(url).then((groupAgent) => {
                resolve(groupAgent.data)
            })
        })
    }
    const getAgentScopeGroups = (scope) => {
        return new Promise(async (resolve, reject) => {
            let url = getAllAgentGroupsURL(authContext._user.profile.tid, scope, authContext._user.profile.oid)
            axios.post(url).then((ScopeGroups) => {
                //resolve(ScopeGroups.data)
                let agentsIds = []
                let getAgentsPromises = []
                for (const group of ScopeGroups.data) {
                    if (group.agentsIds.includes(userId) && group.agentDetails[userId].isSupervisor === true) {
                        agentsIds = [...new Set([...agentsIds, ...group.agentsIds])];
                    }
                }
                for (const agentId of agentsIds) {
                    getAgentsPromises.push(getAgentsGroup(scope, agentId))
                }
                Promise.all(getAgentsPromises).then((results) => {
                    let agentsDetails = []
                    for (const result of results) {
                        agentsDetails = agentsDetails.concat(result)
                    }
                    resolve(agentsDetails)
                })
            });
        })
    }
    const getCallFlowsData = (scops) => {
        let promises = [];
        for (const scope of Object.keys(scops)) {
            promises.push(getCallflows(scope))
        }
        Promise.all(promises).then((results) => {
            var callFlows = []
            for (var scopeCallflows of results) {
                if (Object.values(scopeCallflows).length > 0) {
                    Object.values(scopeCallflows).forEach(scopeCallflow => {
                        callFlows.push(scopeCallflow)
                    });
                }

            }
            props.setCallflows(callFlows)
        })
    };
    const getSupervisedAgents = (scops) => {
        let promises = [];
        for (const scope of Object.keys(scops)) {
            promises.push(getAgentScopeGroups(scope))
        }
        Promise.all(promises).then((results) => {
            var agents = []
            for (var scopeAgents of results) {
                agents = agents.concat(scopeAgents)
            }
            props.setSupervisedAgents(agents)
        })
    };
    const getScops = () => {
        return new Promise(async (resolve, reject) => {
            getToken().then((Token) => {
                const url = getAllScopsUrl(authContext._user.profile.tid, Token)
                axios.get(url).then(async (resultScops) => {
                    resolve(resultScops.data)
                })
            })
        })
    }
    useEffect(() => {
        getScops().then((allUserScops) => {
            getCallFlowsData(allUserScops);
            getSupervisedAgents(allUserScops)
        })
    }, []);
    return (
        <div>
            <RecordedCallsContainer />
        </div>
    )
}
export default RecordingComponent;