import React, { useEffect, useState } from "react";
import { authContext } from "../auth";
import { XMLParser } from 'fast-xml-parser';
import Axios from "axios";
import RecordingTableComponent from './RecordingTableComponent';
import { getRecordingsaccessUrl } from "../urls"
import RecordingPlayer from './RecordingPlayer'
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
  } from "@fluentui/react-components";
const RecordedCallsComponents = (props) => {
    let user = authContext.getCachedUser();
    let userId = user.profile.oid;
    let tenant = user.profile.tid;
    let supervisedAgents = props.supervisedAgents;
    let callflows = props.callflows;
    const [tableItems, setTableItems] = useState([]);
    const setTranscription = async (url, call_id) => {
        try {
            const response = await Axios.get(url);
    
            if(response.data){
                const transcriptionText =  <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Button style={{color:"#343A40"}}>{response.data.substring(0, 20) + "..."}</Button>
                </DialogTrigger>
                <DialogSurface style={{background:'white'}}>
                  <DialogBody>
                    <DialogContent>
                      {response.data}
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button style={{background:'#C0392B',borderRadius:'3px'}} >Close</Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
                setTableItems(prevItems => 
                    prevItems.map(item => 
                        item.Call_id === call_id ? { ...item, transcription: transcriptionText } : item
                    )
                );
            } else {
                setTableItems(prevItems => 
                    prevItems.map(item => 
                        item.Call_id === call_id ? { ...item, transcription: "No transcription" } : item
                    )
                );
            }
    
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle the 404 error quietly without logging it
                setTableItems(prevItems => 
                    prevItems.map(item => 
                        item.Call_id === call_id ? { ...item, transcription: "No transcription" } : item
                    )
                );
            } else {
                console.error("An error occurred:", error.message); // Only log other errors
            }
        }
    };
    
    const getBlobs = (containerDataAccessUrl, storageAccount, blobContainerName, token, id, recordingScope) => {
        Axios.get(containerDataAccessUrl).then((res) => {
            const parser = new XMLParser();
            let callFlowBlobs = parser.parse(res.data);
            let blobs = callFlowBlobs.EnumerationResults.Blobs.Blob;
            if (!blobs.length > 0) {
                blobs = [blobs]
            }
            let newContainerDataAccessUrl = null;
            if (callFlowBlobs.EnumerationResults.NextMarker && callFlowBlobs.EnumerationResults.NextMarker !== "") {
                newContainerDataAccessUrl = "https://" + storageAccount + ".blob.core.windows.net/" + blobContainerName + "?comp=list&restype=container&" + token + "&include=metadata&marker=" + callFlowBlobs.EnumerationResults.NextMarker + "&maxresults=500"
            }
            let formattedBlobs = []
            for (var blob of blobs) {
                if (blob.Metadata && blob.Metadata.timestamp && blob.Metadata.timestamp.toString().length < 12) {
                    let duration = 0;
                    for (const [key, value] of Object.entries(blob.Properties)) {
                        if (key === 'Content-Length') {
                            duration = secondsToHms((value) / (16000 * 1 * 16 / 8))
                        }
                    }
                    let call_id = blob.Name.replace("CallRecordings/", '');
                    call_id = call_id.replace(".wav", '')
                    let fileName = blob.Name;
                    let caller = "";
                    const unixTime = blob.Metadata.timestamp;
                    let transcripturl = blob.Metadata.transcripturl;
                    if (transcripturl) {
                        setTranscription("https://clientsorage.blob.core.windows.net/transcription/"+ call_id+".txt", call_id);
                    }

                    let date = timeStampToDate(unixTime)
                    caller = blob.Metadata.callerid;
                    if (blob.Metadata.callerName) {
                        caller = blob.Metadata.callerName
                    }
                    let callDirection = blob.Metadata.direction ? blob.Metadata.direction : "IN";
                    let row = {
                        key: call_id,
                        Recording:
                            <RecordingPlayer
                                fileName={fileName}
                                blobContainerName={blobContainerName}
                                callFlowToken={token}
                                account={storageAccount}
                            />
                        ,
                        direction: callDirection,
                        recordingScope: recordingScope,
                        id: caller,
                        Duration: duration,
                        Agent_name: blob.Metadata.agentdisplayname,
                        Date: date,
                        Call_type: blob.Metadata.callertype,
                        Call_id: call_id,
                        Timestamp: blob.Metadata.timestamp,
                        transcription:""
                    }
                    formattedBlobs.push(row)
                }
                // setTableRow(formattedBlobs)
            }
            setTableItems(tableItems => [...tableItems, ...formattedBlobs])
            return newContainerDataAccessUrl
        }).then((newContainerDataAccessUrl) => {
            if (newContainerDataAccessUrl !== null) {
                getBlobs(newContainerDataAccessUrl, storageAccount, blobContainerName, token, id, recordingScope)
            }
        }).catch(err => {
            // console.log("Error");
        })

    }
    const timeStampToDate = (unixTime) => {
        var a = new Date(unixTime * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }
    const secondsToHms = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }
    const getSupervisedAgentsRecordingBlobs = () => {
        for (const agent of supervisedAgents) {
            const containerAcessServiceUrl = getRecordingsaccessUrl(tenant, agent.scope, userId, agent.id)
            try {
                Axios.get(containerAcessServiceUrl).then(async (accessData) => {
                    let uri = accessData.data.uri;
                    let storageAccount = uri.substring(8, uri.lastIndexOf(".blob.core.windows"))
                    let blobContainerName = uri.split('.blob.core.windows.net/')[1].split('?st')[0]
                    let token = accessData.data.token;
                    let containerDataAccessUrl = "https://" + storageAccount + ".blob.core.windows.net/" + blobContainerName + "?comp=list&restype=container&include=metadata&maxresults=500&" + token
                    getBlobs(containerDataAccessUrl, storageAccount, blobContainerName, token, agent.id, agent.displayName)
                });
            } catch (error) {
                console.log('no agent recordings')
            }

        }
    }
    const getCallflowsBlobs = () => {
        for (const callflow of callflows) {
            // let testJamesUrl = "http://heedifyprovisioningservices.azurewebsites.net/Callrecordingsaccess/" + "6162036b-af7d-46d3-b4b4-10ee261019df" + "/default/" + "1a7c6593-f912-4a40-a593-9d7674650f83" + "?userid=" + 'd9f54093-036c-4716-9774-1bd82e6d96fd';
            //const containerAcessServiceUrl = getRecordingsaccessUrl("6162036b-af7d-46d3-b4b4-10ee261019df", "default", 'd9f54093-036c-4716-9774-1bd82e6d96fd', "1a7c6593-f912-4a40-a593-9d7674650f83")
            const containerAcessServiceUrl = getRecordingsaccessUrl(tenant, callflow.scope, userId, callflow.id)
            try {
                Axios.get(containerAcessServiceUrl).then(async (accessData) => {
                    let uri = accessData.data.uri;
                    let storageAccount = uri.substring(8, uri.lastIndexOf(".blob.core.windows"))
                    let blobContainerName = uri.split('.blob.core.windows.net/')[1].split('?st')[0]
                    let token = accessData.data.token;
                    let containerDataAccessUrl = "https://" + storageAccount + ".blob.core.windows.net/" + blobContainerName + "?comp=list&restype=container&include=metadata&maxresults=500&" + token
                    getBlobs(containerDataAccessUrl, storageAccount, blobContainerName, token, callflow.id, callflow.displayName)
                });
            } catch (error) {
                console.log('no call flow recording ')
            }
        }

    }
    useEffect(() => {
        if (supervisedAgents) {
            getSupervisedAgentsRecordingBlobs()
        }
    }, [supervisedAgents])
    useEffect(() => {
        if (callflows) {
            getCallflowsBlobs();
        }
    }, [callflows])
    return (
        <div>
            <RecordingTableComponent
                tableItems={tableItems}
            />
        </div>
    )
}
export default RecordedCallsComponents;