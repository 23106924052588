import React, { useEffect, useState } from "react";
import { authContext } from "../../auth";
import { useParams } from "react-router-dom";
import axios from "axios";
import { parse } from 'fast-xml-parser';
import { getAgentUrl, getMyAgentProfileUrl, getRecordingsaccessUrl } from "../../urls";
import { getToken, getUserDetails } from '../Graph';
import RecordingPlayer from '../RecordingPlayer';
import RecordingTableComponent from '../RecordingTableComponent'
const RecordedCallsComponents = (props) => {
    const [agent, setAgent] = useState({})
    const [agentCalls, setAgentCalls] = useState({})
    let user = authContext.getCachedUser();
    let userId = user.profile.oid;
    let tenant = user.profile.tid;
    const params = useParams();
    let agentId = params.agentID
    let callId = params.callId
    const getBlobs = (containerDataAccessUrl, storageAccount, blobContainerName, token, id, recordingScope) => {
        axios.get(containerDataAccessUrl).then((res) => {
            let callFlowBlobs = parse(res.data);
            let blobs = callFlowBlobs.EnumerationResults.Blobs.Blob;
            if (!blobs.length > 0) {
                blobs = [blobs]
            }
            if (callFlowBlobs.EnumerationResults.NextMarker && callFlowBlobs.EnumerationResults.NextMarker !== "") {
                let newContainerDataAccessUrl = "https://" + storageAccount + ".blob.core.windows.net/" + blobContainerName + "?comp=list&restype=container&" + token + "&include=metadata&marker=" + callFlowBlobs.EnumerationResults.NextMarker
                getBlobs(newContainerDataAccessUrl, storageAccount, blobContainerName, token, id, recordingScope)
            }
            for (const blob of blobs) {
                if (recordingScope === "Cassandra Dunn") {
                    blob.Metadata = {
                        "timestamp": 1683115940,
                        "callerid": "399b3790-2b9d-4994-a490-3fe3d8d2bafb",
                        "callertype": "user",
                        "appinstanceid": "6d2f8e1d-f53b-464f-aa07-679a06f471e2",
                        "transcripturl": "https://",
                        "agentdisplayname": "Cassandra Dunn",
                        "agentid": "6d2f8e1d-f53b-464f-aa07-679a06f471e2",
                        "direction": "IN"
                    }
                }
                blob['recordingScope'] = recordingScope;
                blob['token'] = token;
                blob['account'] = storageAccount;
                blob['blobContainerName'] = blobContainerName;
                setTableRow(blob)
            }
        }).catch(err => {
            console.log("Error");
        })

    }
    const getSupervisedAgentRecordingBlobs = (agent) => {
        const containerAcessServiceUrl = getRecordingsaccessUrl(tenant, agent.scope, userId, agentId)
        try {
            axios.get(containerAcessServiceUrl).then(async (accessData) => {
                let uri = accessData.data.uri;
                let storageAccount = uri.substring(8, uri.lastIndexOf(".blob.core.windows"))
                let blobContainerName = uri.split('.blob.core.windows.net/')[1].split('?st')[0]
                let token = accessData.data.token;
                let containerDataAccessUrl = "https://" + storageAccount + ".blob.core.windows.net/" + blobContainerName + "?comp=list&restype=container&include=metadata&maxresults=500&" + token
                getBlobs(containerDataAccessUrl, storageAccount, blobContainerName, token, agentId, agent.displayName)
            });
        } catch (error) {
            console.log('no agent recordings')
        }
    }
    const timeStampToDate = (unixTime) => {
        var a = new Date(unixTime * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }
    const secondsToHms = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }
    async function setTableRow(call) {
        if (call.Metadata && call.Metadata.timestamp && call.Metadata.timestamp.toString().length < 12) {
            let recordingScope = call.recordingScope
            let blobContainerName = call.blobContainerName;
            let callFlowToken = call.token;
            let account = call.account;
            let duration = 0;
            for (const [key, value] of Object.entries(call.Properties)) {
                if (key === 'Content-Length') {
                    duration = secondsToHms((value) / (16000 * 1 * 16 / 8))
                }
            }
            let call_id = call.Name.replace("CallRecordings/", '');
            call_id = call_id.replace(".wav", '')
            let fileName = call.Name;
            let caller = "";
            const unixTime = call.Metadata.timestamp;
            let date = timeStampToDate(unixTime)
            if (call.Metadata.callertype === "phone") {
                caller = call.Metadata.callerid;
            } else {
                let user = await getUserDetails(call.Metadata.callerid)
                caller = user.displayName
            }
            let callDirection = call.Metadata.direction ? call.Metadata.direction : "IN";
            let row = {
                key: call_id,
                Recording:
                    <RecordingPlayer
                        fileName={fileName}
                        blobContainerName={blobContainerName}
                        callFlowToken={callFlowToken}
                        account={account}
                    />
                ,
                direction: callDirection,
                recordingScope: recordingScope,
                id: caller,
                Duration: duration,
                Agent_name: call.Metadata.agentdisplayname,
                Date: date,
                Call_type: call.Metadata.callertype,
                Call_id: call_id
            }
            setAgentCalls(agentCalls => ({
                ...agentCalls,
                [call_id]: row
            }));

        }
    }
    const getAgent = (scope) => {
        return new Promise(async (resolve, reject) => {
            let url = getAgentUrl(tenant, scope, userId, agentId, null)
            axios.get(url).then((agent) => {
                setAgent(agent.data)
                resolve(agent.data)
            })
        })
    }
    const getMyAgentProfile = (tenant, useId, Token) => {
        return new Promise((resolve, reject) => {
            let url = getMyAgentProfileUrl(tenant, useId, Token);
            axios.get(url).then((Result) => {
                resolve(Result.data)
            });
        });
    };
    useEffect(() => {
        getToken().then(async (Token) => {
            getMyAgentProfile(tenant, userId, Token).then((myAgentProfile) => {
                let scope = myAgentProfile.scope
                getAgent(scope).then((agent) => {
                    getSupervisedAgentRecordingBlobs(agent)
                });
            });
        })


    }, [])

    return (
        <RecordingTableComponent
            tableItems={Object.values(agentCalls)}
            defaultCall={callId}
        />
    )
}
export default RecordedCallsComponents;