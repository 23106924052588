
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
class RangeDurationFilter extends Component {
    constructor(props) {
        super(props);
        this.filter = this.filter.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    getValue() {
        return {
            minMinutes: this.minMinutes.value,
            minSeconds: this.minSeconds.value,
            maxMinutes: this.maxMinutes.value,
            maxSeconds: this.maxSeconds.value,
        };
    }
    filter() {
        this.props.onFilter(this.getValue());
    }
    render() {
        return <div id="filter-duration">
            <div style={{ display: "inline-block" }}>
                <span >From</span>
                <div style={{ display: "flex" }}>
                    <input style={{ display: "inline-block", width: "100px" }} key="minMinutes" placeholder="Minutes" onChange={this.filter} ref={node => (this.minMinutes = node)} id='minm' type='number' default="00" min='00' max='59' />

                    <input style={{ display: "inline-block", width: "100px" }} key="minSeconds" placeholder="Seconds" onChange={this.filter} ref={node => (this.minSeconds = node)} id='mins' type='number' default="00" min='00' max='59' />
                </div>
            </div>
            <div style={{ display: "inline-block" }}>
                <span>To</span>
                <div style={{ display: "flex" }}>
                    <input style={{ display: "inline-block", width: "100px" }} key="maxMinutes" placeholder="Minutes" onChange={this.filter} ref={node => (this.maxMinutes = node)} id='maxm' type='number' min='0' max='59' />

                    <input style={{ display: "inline-block", width: "100px" }} key="maxSeconds" placeholder="Seconds" onChange={this.filter} ref={node => (this.maxSeconds = node)} id='maxs' type='number' min='0' max='59' />
                </div>
            </div>
        </div>
    }
};
export default (RangeDurationFilter)

