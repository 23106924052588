import React, { useState } from "react";
import { GoPlay } from 'react-icons/go'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const RecordingPlayer = (props) => {
    const [isPlayed, setIsPlayed] = useState(false)
    return (
        isPlayed ?
            <AudioPlayer
                src={"https://" + props.account + ".blob.core.windows.net/" + props.blobContainerName + "/" + props.fileName + "?" + props.callFlowToken}
                layout="horizontal-reverse"
                autoPlayAfterSrcChange={false}
            />
            :
            <GoPlay className="play-btn" onClick={() => {
                setIsPlayed(true)
            }} />
    )
}
export default RecordingPlayer;