import { AuthenticationContext } from "react-adal";
export const config = {
    //clientId: '2eaa21a7-3855-4be3-9bb3-926ce86da20c',
    clientId: "a1334a47-7e14-47d2-9e4a-519efa295d30",
    // redirectUri must be in the list of redirect URLs for the Azure AD app
    cacheLocation: "localStorage",
    redirectUri: "https://recordings.heedify.io/",
};

export const authContext = new AuthenticationContext(config);
