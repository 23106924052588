
import { authContext } from "../auth";
authContext.handleWindowCallback();

var graph = require("@microsoft/microsoft-graph-client");
const RECEPTION_BOT_URL = "http://localhost:7071/api/ReceptionistBot/";
async function getAuthenticatedClient(accessToken) {
    const client = await graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken);
        },
    });

    return client;
}

export async function getToken(x) {
    return new Promise((resolve, reject) => {
        authContext.acquireToken(
            "https://graph.microsoft.com",
            function (errDesc, token, err, tokenType) {
                resolve(token);
            }
        );
    });
}
export async function getUserDetails(userId) {
    var accessToken = await getToken(null);
    const client = await getAuthenticatedClient(accessToken);
    const user = await client.api("/users/" + userId).get();
    return user;
}